import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ConfigProvider, theme} from "antd";
import {WebAppProvider} from "@vkruglikov/react-telegram-web-app";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <WebAppProvider
        options={{
            smoothButtonsTransition: true,
        }}
    >
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <App/>
        </ConfigProvider>
    </WebAppProvider>
);
