import React, {useEffect, useState} from 'react';
import './App.css';
import 'antd/'
import {DatePicker, Form, Radio, Input, Button} from "antd";
import {useInitData} from "@vkruglikov/react-telegram-web-app";

const {RangePicker} = DatePicker
const {TextArea} = Input

function App() {
    const [initDataUnsafe, initData] = useInitData()
    const [loaded, setLoaded] = useState(false)
    const [hasDayoffs, setHasDayoffs] = useState(false)
    const [form] = Form.useForm()

    const submitVacation = (values: any) => {
        if (initData)
            fetch(`${process.env.REACT_APP_BASE_BACKEND_URL}api/vacation`, {
                headers: {
                    'x-init-data': initData,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    comment: values.comment,
                    start_date: new Date(values.date[0].endOf('day').unix() * 1000),
                    end_date: new Date(values.date[1].endOf('day').unix() * 1000),
                    type: values.type
                })
            }).then(r => {
                if (values.type === 'VACATION')  {
                    alert('Отправлено на согласование!')
                } else {
                    alert('Успешно сохранено!')
                }
                form.resetFields()
            })
    }


    useEffect(() => {
        if (initData) {
            fetch(`${process.env.REACT_APP_BASE_BACKEND_URL}api/me`, {
                headers: {
                    'x-init-data': initData
                }
            }).then(r => r.json()).then(r => {
                if (r) {
                    setLoaded(true)
                    setHasDayoffs(r.dayoffs)
                } else {
                    alert('Для начала необходимо заполнить информацию в боте')
                }
            })
        }

    }, [initData]);


    if (!loaded) {
        return null
    }

    return (
        <Form onFinish={submitVacation} form={form}>
            <Form.Item name="type" label="Выберите тип заявления"
                       rules={[{required: true, message: 'Это обязательное поле'}]}>
                <Radio.Group>
                    <Radio value="VACATION">Отпуск</Radio>
                    <Radio value="SICK">Больничный</Radio>
                    {hasDayoffs && <Radio value="DAYOFF">Дей-оф</Radio>}
                </Radio.Group>
            </Form.Item>
            <Form.Item name="date" label="Укажите даты" rules={[{required: true, message: 'Это обязательное поле'}]}>
                <RangePicker placeholder={["Дата начала", "Дата окончания"]} style={{width: '100%', height: 40}}
                             size="middle"/>
            </Form.Item>
            <Form.Item name="comment" label="Добавьте комментарий"
                       rules={[{required: true, message: 'Это обязательное поле'}]}>
                <TextArea rows={4}/>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" style={{height: 40, width: '100%'}}>
                    Отправить
                </Button>
            </Form.Item>
        </Form>
    );
}

export default App;
